class Submenu {
  constructor(el, header) {
    this.el = el;
    this.header = header;
    this.inner = el.querySelector('.menu-item__link');
    this.link = el.querySelector('.menu-item__link a');
    this.menu = el.closest('.header__nav');
    this.submenu = el.querySelector('.submenu');
    this.delay = 300;
    this.active = false;
    this.button = el.querySelector(".menu-item__link button");
    this.container = el.querySelector(".menu-item__submenu");
    this.init();
  }

  init() {
    this.el.addEventListener("mouseenter", this.activateWithDelay.bind(this));
    this.el.addEventListener("mouseleave", this.deactivateWithDelay.bind(this));
    this.button.addEventListener("click", this.toggle.bind(this));
    this.inner.addEventListener("click", (e) => {
      if (e.target == this.inner) {
        this.toggle(e);
      }
    });
  }

  activateWithDelay(e) {
    this.header.nav.querySelector('.--last-active')?.classList.remove('--last-active');
    this.el.classList.add('--last-active');
    if (this.el.classList.contains('--deactivate-intent')) {
      this.el.classList.remove('--deactivate-intent');
      return
    }

    if (this.menu.classList.contains('--menu-entered')) {
      this.el.classList.add('--activate-intent');
      this.el.classList.remove('--deactivate-intent');
      this.activate();
      setTimeout(() => {
        this.el.classList.remove('--activate-intent');
      }, 0);
      return
    }

    this.el.classList.add('--activate-intent');
    setTimeout(() => {
      if (this.el.classList.contains('--activate-intent')) {
        this.el.classList.remove('--activate-intent');
        this.activate();
      }
    }, this.delay);
  }

  deactivateWithDelay(e) {
    this.el.classList.remove('--hovering');
    if (this.el.classList.contains('--activate-intent')) {
      this.el.classList.remove('--activate-intent');
      return
    }
    this.el.classList.add('--deactivate-intent');
    setTimeout(() => {
      this.el.classList.remove('--last-active');
      if (this.el.classList.contains('--deactivate-intent')) {
        this.el.classList.remove('--deactivate-intent');
        this.deactivate(false);
      }
    }, this.delay);
  }

  activate() {
    if (this.active) return
    this.header.activeSubmenu?.deactivateWithDelay();
    this.header.activeLightbox?.deactivate(false);
    this.active = true;
    this.el.classList.add("--active");
    this.container.setAttribute("aria-hidden", false);
    this.button.setAttribute("aria-expanded", true);
    this.header.activeSubmenu = this;
    if (!this.menu.classList.contains("--menu-entered")) {
      this.el.classList.add('--item-entered-first');
    }
    this.menu.classList.add("--menu-entered");
    window.app_listeners?.add('keydown', 'submenu-focus-handler', this.keydownHandler.bind(this));
  }

  keydownHandler(e) {
    if (!this.active) return
    if (e.key == 'Escape') {
      this.deactivate();
      e.preventDefault();
    }
  }

  deactivate(returnFocus = true) {
    if (!this.active) return
    this.active = false;
    this.el.classList.remove("--active");
    this.container.setAttribute("aria-hidden", true);
    this.button.setAttribute("aria-expanded", false);
    this.header.activeSubmenu = null;
    this.el.classList.remove('--item-entered-first');
    window.app_listeners?.remove('keydown', 'submenu-focus-handler');
    if (returnFocus) { this.button.focus() };
    setTimeout(() => {
      const active_menus = document.querySelectorAll(".menu-item.--active, .menu-item.--activate-intent, .menu-item.--deactivate-intent");
      if (!active_menus || active_menus.length == 0) {
        this.menu.classList.remove("--menu-entered");
      }
    }, 0);
  }

  toggle(e = null) {
    if (this.active) {
      if (!this.el.classList.contains('--activate-intent')) {
        this.deactivate(e && e.pointerType == "");
      }
    } else {
      this.activate();
    }
    e?.preventDefault();
  }

}

export default Submenu;