class Lightbox {
  constructor(el, header) {
    this.el = el;
    this.header = header;
    this.active = false;
    this.button = el.querySelector(':scope > button, :scope > [role="button"], :scope > span > button');
    this.container = el.querySelector(".menu-lightbox");
    this.delay = 200;
    this.activateTimer = null;
    this.deactivateTimer = null;
    this.init();
  }

  init() {
    this.button.addEventListener("click", this.toggle.bind(this));
    if (!this.el.classList.contains("--reveal-on-click")) {
      this.el.addEventListener("mouseenter", this.activateWithDelay.bind(this));
      this.el.addEventListener("mouseleave", this.deactivateWithDelay.bind(this, false));
    }
    this.el
      .querySelector(".lightbox-clickable-backdrop")
      ?.addEventListener("click", this.deactivate.bind(this, false));
    const additionalCloses = this.el.querySelectorAll("#basket-close");
    if (additionalCloses && additionalCloses.length != 0) {
      additionalCloses.forEach((addClose) => {
        addClose.addEventListener("click", this.deactivate.bind(this));
      });
    }
  }

  activateWithDelay() {
    this.header.scrollHandler();
    clearTimeout(this.deactivateTimer);
    this.activateTimer = setTimeout(() => {
      this.activate()
    }, this.delay)
  }

  deactivateWithDelay() {
    clearTimeout(this.activateTimer);
    this.deactivateTimer = setTimeout(() => {
      this.deactivate(false)
    }, this.delay)
  }

  activate() {
    if (this.active) return
    this.header.scrollHandler();
    this.active = true;
    this.header.activeLightbox?.deactivate(false);
    this.header.activeSubmenu?.deactivate(false);
    this.header.activeLightbox = this;
    if (this.el.classList.contains('mobile-bar__cart')) {
      document.documentElement.classList.add("--freeze-scroll");
      this.header.mobileHeader.deactivate();
    }
    this.el.classList.add("--active");
    this.container.setAttribute("aria-hidden", false);
    this.button.setAttribute("aria-expanded", true);
    window.app_listeners?.add('click', 'lightbox-click', this.deactivateClickOutside.bind(this));
    window.app_listeners?.add('keydown', 'lightbox-keydown', this.deactivateOnEsc.bind(this));
  }

  deactivate(returnFocus = true) {
    if (!this.active) return
    this.active = false;
    this.header.activeLightbox = null;
    if (this.el.classList.contains('mobile-bar__cart')) {
      document.documentElement.classList.remove("--freeze-scroll");
    }
    this.el.classList.remove("--active");
    this.button.setAttribute("aria-expanded", false);
    this.container.setAttribute("aria-hidden", true);
    window.app_listeners?.remove('click', 'lightbox-click');
    window.app_listeners?.remove('keydown', 'lightbox-keydown');
    if (returnFocus) this.button.focus();
  }

  toggle() {
    this.active ? this.deactivate(false) : this.activate();
  }

  deactivateOnEsc(e) {
    if (27 == e.keyCode) {
      this.deactivate();
    }
  }

  deactivateClickOutside(e) {
    if (!this.el.contains(e.target) && e.target != this.el) {
      this.deactivate();
    }
  }
}

export default Lightbox;