class Listeners {

  constructor() {
    this.resizeObserver = null;
    this.changeObserver = null;
    this.scroll = new Map();
    this.keydown = new Map();
    this.focus = new Map();
    this.click = new Map();
    this.resize = new Map();
    this.change = new Map();
    this.popstate = new Map();
    this.init();
  }

  add(eventType, key, fn, prop = null) {
    if (prop) {
      this[eventType].set(key, { fn, prop });
    } else {
      this[eventType].set(key, { fn });
    }
  }

  remove(eventType, key) {
    if (this[eventType].has(key)) {
      this[eventType].delete(key);
    }
  }

  scrollHandler(e) {
    this.scroll.forEach((obj => {
      if (obj.hasOwnProperty('prop')) {
        obj.fn(e, obj.prop);
      } else {
        obj.fn(e);
      }
    }))
  }

  keydownHandler(e) {
    this.keydown.forEach((obj, name) => {
      if (obj.hasOwnProperty('prop')) {
        obj.fn(e, obj.prop);
      } else {
        obj.fn(e);
      }
    })
  }

  focusHandler(e) {
    this.focus.forEach((obj => {
      if (obj.hasOwnProperty('prop')) {
        obj.fn(e, obj.prop);
      } else {
        obj.fn(e);
      }
    }))
  }

  resizeHandler() {
    this.resize.forEach((obj => {
      if (obj.hasOwnProperty('prop')) {
        obj.fn(obj.prop);
      } else {
        obj.fn();
      }
    }))
  }

  clickHandler(e) {
    this.click.forEach((obj => {
      if (obj.hasOwnProperty('prop')) {
        obj.fn(e, obj.prop);
      } else {
        obj.fn(e);
      }
    }))
  }

  popstateHandler(e) {
    this.popstate.forEach((obj => {
      if (obj.hasOwnProperty('prop')) {
        obj.fn(e, obj.prop);
      } else {
        obj.fn(e);
      }
    }))
  }

  changeHandler(){
    this.change.forEach((obj => {
      if (obj.hasOwnProperty('prop')) {
        obj.fn(e, obj.prop);
      } else {
        obj.fn(e);
      }
    }))
  }

  init() {
    window.addEventListener('scroll', this.scrollHandler.bind(this));
    window.addEventListener('popstate', this.popstateHandler.bind(this));
    window.addEventListener('focus', this.focusHandler.bind(this), true);
    document.addEventListener('click', this.clickHandler.bind(this), true);
    document.addEventListener('keydown', this.keydownHandler.bind(this));
    try {
      this.changeObserver = new MutationObserver(this.changeHandler.bind(this));
      this.changeObserver.observe(document.documentElement, {childList: true, attributes: true, subtree: true});
    } catch(e){}
    this.initResizeListener();
  }

  initResizeListener(){
    try {
      this.resizeObserver = new ResizeObserver(this.resizeHandler.bind(this));
      let proxy = document.querySelector('.resize-proxy');
      if (!proxy){
        const proxyEl = document.createElement('div');
        proxyEl.classList.add('resize-proxy');
        document.body.insertBefore(proxyEl, document.body.querySelector('div'));
        proxy = document.querySelector('.resize-proxy');
      }
      if (proxy){
        this.resizeObserver.observe(proxy);
      } else {
        this.resizeObserver.observe(document.body);
      }
    } catch (e){
      window.addEventListener('resize', this.resizeHandler.bind(this));
    }
  }

  disconnect() {
    window.removeEventListener('scroll', this.scrollHandler.bind(this));
    window.removeEventListener('popstate', this.popstateHandler.bind(this));
    window.removeEventListener('keydown', this.keydownHandler.bind(this));
    window.removeEventListener('focus', this.focusHandler.bind(this), true);
    document.removeEventListener('click', this.clickHandler.bind(this), true);
    if (this.resizeObserver){
      this.resizeObserver.disconnect();
    } else {
      window.removeEventListener('resize', this.resizeHandler.bind(this));
    }
    this.changeObserver?.disconnect();
  }

}

export default Listeners