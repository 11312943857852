import Utility from "../../base/Utility";

class ProductDisplay {
    constructor() {
        this.isBlueBarracuda = window.isBlueBarracuda;
        this.isPdpPage = window.isPdpPage;
        this.isPreview = window.isPreview;
        this.isPickupPage = window.isPickupPage;
        this.init();
    }

    init() {
        if (!this.isPdpPage && !this.isPickupPage && !this.isPreview) return;
        if (this.isBlueBarracuda) {
            // use this instead of the proper way of js URL constructor, as imghost is not always a root.
            const url = imghost + "/jl/assets/js/build/blue-barracuda-bundle.js"
            const blueBarracudaUrl = new URL(url);
            Utility.injectResources('blueBarracuda', [blueBarracudaUrl], () => {
            })
        }
    }
}


export default ProductDisplay