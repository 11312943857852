"use strict";

import Global from "./Global";
import Utility from "./Utility";

class Page {
  constructor() {
    this.injectedResources = new Map();
    this.gap;
    this.init();
  }

  init() {
    this.update();
    document.documentElement.classList.add("--init");
    document.querySelector('.page-wrapper')?.setAttribute('aria-hidden', false);
    window.app_listeners?.add('resize', 'page-resize', this.update.bind(this));
  }

  update() {
    Global.scrollBarWidth = Utility.getScrollBarWidth();
    document.body.style.setProperty('--scrollbar-width', Global.scrollBarWidth + 'px');
    document.body.style.setProperty('--client-height', Utility.getClientHeight() + 'px');
  }

  freezeScroll(blocking = true) {
    if (document.body.classList.contains('--scroll-freeze')) return
    if (!this.gap) {
      this.gap = Global.scrollBarWidth;
    }
    if (blocking) {
      document.querySelector('.page-wrapper')?.setAttribute('aria-hidden', true);
    }
    document.body.classList.add('--scroll-freeze');
    document.body.style.setProperty('--scrollbar-gap', this.gap + 'px');
  }

  unfreezeScroll() {
    if (!document.body.classList.contains('--scroll-freeze')) return
    if (this.gap) {
      this.gap = null;
    }
    document.querySelector('.page-wrapper')?.setAttribute('aria-hidden', false);
    document.body.classList.remove('--scroll-freeze');
    document.body.style.setProperty('--scrollbar-gap', '');
  }

}

export default Page;