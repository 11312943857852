"use strict";

import Utility from "../../base/Utility";
import HeaderMobile from "./HeaderMobile";
import Lightbox from "./Lightbox";
import Submenu from "./Submenu";


class Header {
  constructor() {
    this.nav = document.querySelector("#desktop-header");
    this.activeSubmenu = null;
    this.lightboxes = new Map();
    this.activeLightbox = null;
    this.mobileHeader = null;
    this.init();
  }

  init() {
    if (!this.nav) return;
    this.initLightboxes();
    this.initSubmenus();
    this.mobileHeader = new HeaderMobile(this);
    Utility.applyToAll(".basket-counter", this.cartWatcher);
    window.app_listeners?.add('scroll', 'header-scroll-handler', this.scrollHandler.bind(this));
    window.app_listeners?.add('focus', 'header-focus-handler', this.focusHandler.bind(this));
    window.app_listeners?.add('keydown', 'header-keydown-handler', this.keydownHandler.bind(this));
  }

  keydownHandler(e) {
    if (e.key === 'Escape') {
      this.activeSubmenu?.deactivate();
      this.activeLightbox?.deactivate();
    }
  }

  focusHandler() {
    if (document.activeElement == document.body) return
    if (this.activeLightbox && !this.activeLightbox.el.contains(document.activeElement)) {
      this.activeLightbox.deactivate(false);
    } else if (this.activeSubmenu && !this.activeSubmenu.el.contains(document.activeElement)) {
      this.activeSubmenu.deactivate(false);
    }
  }

  cartWatcher(el) {
    let holder = el.closest("button");
    if (!holder) return;
    function updateCartText() {
      let count = parseInt(el.textContent);
      let label = "gift basket summary";
      let copy = label + ", empty";
      if (!isNaN(count) && count != "" && count != 0) {
        copy = (count == 1) ? label + ", has 1 item" : label + ", has " + count + " items";
      }
      holder.setAttribute("aria-label", copy);
    }

    updateCartText();
    try {
      const observer = new MutationObserver(updateCartText);
      observer.observe(el, { subtree: true, childList: true });
    } catch(e){}
  }

  scrollHandler() {
    const scroll = window.pageYOffset;
    if (scroll >= 1) {
      document.documentElement.classList.add("--scrolled");
    } else if (!document.documentElement.classList.contains("--scrolling-disabled")){
      document.documentElement.classList.remove("--scrolled");
    }
    if (Utility.isHidden(this.nav)) {
      this.nav = document.querySelector('.mobile-header');
    }
    document.documentElement.style.setProperty(
      "--top-banner-height",
      Math.max(0, this.nav.getBoundingClientRect().top) + "px"
    );
  };

  initLightboxes() {
    const lightboxes = document.querySelectorAll('.--has-lightbox');
    if (!lightboxes || lightboxes.lenght == 0) return
    lightboxes.forEach((lightbox, counter) => {
      let name = '';
      if (lightbox.classList.contains('--cart')) {
        name = (lightbox.id) != '' ? lightbox.id : `lightbox-cart-instance-${counter}`;
      } else {
        name = (lightbox.id) != '' ? lightbox.id : `lightbox-instance-${counter}`;
      }
      lightbox.id = name;
      this.lightboxes.set(name, new Lightbox(lightbox, this))
    })
  }

  initSubmenus() {
    const submenus = this.nav.querySelectorAll('.menu-item.--has-dropdown');
    if (!submenus || submenus.length == 0) return
    submenus.forEach(el => new Submenu(el, this));
  }

}

export default Header;