import Utility from "../../base/Utility";

class HeaderMobile {
  constructor(header) {
    this.header = header;
    this.active = false;
    this.trigger = document.querySelector(".hamburger-menu");
    this.container = document.querySelector(".offsite-nav");
    this.activeDropdown = null;
    this.activeDrawer = null;
    if (!this.trigger || !this.container) return;
    this.init();
  }

  init() {
    this.trigger.addEventListener("click", () => { this.active ? this.deactivate() : this.activate() });
    this.initDropdowns();
    this.initDrawers();
  }

  activate() {
    if (this.active) return
    this.header.scrollHandler();
    this.header.activeLightbox?.deactivate();
    this.active = true;
    Utility.trapFocus(".offsite-nav a", ".mobile-bar__cart .cart-button");
    Utility.trapFocus(
      ".mobile-bar__trigger button",
      ".mobile-menu-item:last-child a"
    );
    document.documentElement.classList.add("--mobile-menu-open");
    this.container.setAttribute("aria-hidden", false);
    this.trigger.setAttribute("aria-expanded", true);
    window.app_listeners?.add("keydown", "mobile-header-keydown", this.keydownHandler.bind(this));
  }

  deactivate() {
    if (!this.active) return
    this.active = false;
    Utility.trapFocus(
      ".offsite-nav a",
      ".mobile-bar__cart .cart-button",
      false
    );
    Utility.trapFocus(
      ".mobile-bar__trigger button",
      ".mobile-menu-item:last-child a",
      false
    );
    this.activeDrawer?.deactivate(false);
    this.activeDropdown?.deactivate(false);
    this.container.style.setProperty("--scroll-offset", "0px");
    this.container.setAttribute("aria-hidden", true);
    this.trigger.setAttribute("aria-expanded", false);
    document.documentElement.classList.remove("--mobile-menu-open");
    document.documentElement.classList.add("--mobile-menu-removing");
    setTimeout(function () {
      document.documentElement.classList.remove("--mobile-menu-removing");
    }, 200);

    window.app_listeners?.remove("keydown", "mobile-header-keydown");

    setTimeout(() => {
      this.trigger.focus();
    }, 0);
  }

  keydownHandler(e) {
    if (e.key === "Escape") {
      if (this.activeDrawer) {
        this.activeDrawer.deactivate();
      } else if (this.activeDropdown) {
        this.activeDropdown.deactivate();
      } else {
        this.deactivate();
      }
    }
  }

  initDropdowns() {
    const dropdowns = this.container.querySelectorAll('.mobile-menu-item.--has-dropdown');
    if (!dropdowns || dropdowns.length == 0) return
    dropdowns.forEach(el => new Dropdown(el, this));
  }

  initDrawers() {
    const drawers = this.container.querySelectorAll('.side-trigger');
    if (!drawers || drawers.length == 0) return
    drawers.forEach(el => new Drawer(el, this));
  }
}


class Dropdown {
  constructor(el, headerMobile) {
    this.el = el;
    this.active = false;
    this.headerMobile = headerMobile;
    this.trigger = el.querySelector(".dropdown-trigger");
    this.container = el.querySelector(".mobile-dropdown");
    this.init();
  }

  init() {
    this.trigger.addEventListener("click", () => { this.active ? this.deactivate() : this.activate() });
    Utility.addResizeObserver(this.container, null, '--dropdown-height');
  }

  deactivate(returnFocus = true) {
    if (!this.active) return
    this.active = false;
    this.headerMobile.activeDropdown = null;
    this.el.classList.add("--removing");
    this.trigger.setAttribute("aria-expanded", false);
    this.container.setAttribute("aria-hidden", true);
    setTimeout(() => {
      this.el.classList.remove("--removing");
      this.el.classList.remove("--active");
      if (returnFocus) this.trigger.focus();
    }, 200);
  }

  activate() {

    if (this.active) return
    this.active = true;
    this.headerMobile.activeDropdown?.deactivate(false);
    this.headerMobile.activeDropdown = this;
    this.trigger.setAttribute("aria-expanded", true);
    this.container.setAttribute("aria-hidden", false);
    this.el.classList.add("--activating");
    setTimeout(() => {
      this.el.classList.remove("--activating");
      this.el.classList.add("--active");
    }, 10);
  }
}

class Drawer {
  constructor(el, headerMobile) {
    this.el = el;
    this.headerMobile = headerMobile;
    this.active = false;
    this.holder = document.querySelector(".side-menu__holder");
    this.id = el.getAttribute("aria-controls");
    this.target = document.querySelector(".side-menu__content#" + this.id);
    if (!this.target) return;
    this.close = this.target.querySelector(".submenu__close");
    this.init()
  }

  init() {
    this.el.addEventListener("click", () => { this.active ? this.deactivate() : this.activate() });
    this.close.addEventListener("click", () => { this.deactivate() })
  }

  activate() {
    if (this.active) return
    this.headerMobile.activeDrawer?.deactivate();
    const lastActive = document.querySelector(".side-menu__content:not(.hidden)");
    if (lastActive && lastActive != this.target) lastActive.classList.add("hidden");
    this.active = true;
    this.headerMobile.activeDrawer = this;
    this.headerMobile.container.style.setProperty("--scroll-offset", this.headerMobile.container.scrollTop + "px");
    this.headerMobile.container.classList.add("--dropdown-opened");
    this.close.setAttribute("aria-expanded", true);
    this.el.setAttribute("aria-expanded", true);
    this.holder.setAttribute("aria-hidden", false);
    this.target.classList.remove("hidden");
    this.target.setAttribute("aria-hidden", false);

    Utility.trapFocus(
      "#" + this.id + " .submenu__close",
      "#" + this.id + " .submenu__menu li:last-child a",
      true
    );

    setTimeout(() => {
      this.close.focus();
    }, 100);
  }

  deactivate(returnFocus = true) {
    if (!this.active) return
    this.active = false
    this.headerMobile.activeDrawer = null;
    this.headerMobile.container.style.setProperty("--scroll-offset", this.headerMobile.container.scrollTop + "px");
    this.headerMobile.container.classList.remove("--dropdown-opened");
    this.close.setAttribute("aria-expanded", false);
    this.el.setAttribute("aria-expanded", false);
    this.holder.setAttribute("aria-hidden", true);
    this.target.setAttribute("aria-hidden", true);

    Utility.trapFocus(
      "#" + this.id + " .submenu__close",
      "#" + this.id + " .submenu__menu li:last-child a",
      false
    );
    if (returnFocus) {
      setTimeout(() => {
        this.el.focus();
      }, 100);
    }
  }
}

export default HeaderMobile;