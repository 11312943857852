"use strict";

import Global from "./Global";
import Utility from "./Utility";

class Accessibility {
  constructor() {
    this.init();
  }

  init() {
    this.ariaHiddenInit(document);
  }

  addFocusable(el) {
    if (el.classList.contains('ignore-tab')) return
    if (el.classList.contains('focusable-element')){
      el.setAttribute("tabindex", "0");
    } else {
      el.removeAttribute("tabindex");
    }
  }

  removeFocusable(el) {
    if (el.classList.contains('ignore-tab')) return
    el.setAttribute("tabindex", "-1");
  }

  ariaHiddenInit(root = document) {
    let focusables = root.querySelectorAll(
      '[aria-hidden="true"], [aria-hidden="false"]'
    );
    if (focusables && focusables.length != 0) {
      focusables.forEach(el => this.ariaHiddenElInit(el, root));
    }
  }

  ariaHiddenElInit(el, root){
    if (el.getAttribute("aria-hidden") == "true") {
      Utility.applyToAll(Global.focusableQueryString, this.removeFocusable, el);
      if (!el.hasAttribute('data-ignore-inert')){
        el.setAttribute("inert", true);
      }
    } else {
      Utility.applyToAll(Global.focusableQueryString, this.addFocusable, el);
      el.removeAttribute("inert");
    }
    if (root == document) {
      this.initAriaHiddenObserver(el);
    }
  }

  initAriaHiddenObserver(el) {
    const onMutate = (mutationList) => {
      mutationList.forEach((mutation) => {
        if (mutation.type != "attributes") return;
        if (mutation.target.getAttribute("aria-hidden") == "true") {
          Utility.applyToAll(Global.focusableQueryString, this.removeFocusable, el);
          if (!el.hasAttribute('data-ignore-inert')){
            el.setAttribute("inert", true);
          }
        } else {
          Utility.applyToAll(Global.focusableQueryString, this.addFocusable, el);
          if (!el.hasAttribute('data-ignore-inert')){
            el.removeAttribute("inert");
          }
          this.ariaHiddenInit(el);
          window.app_lazyloader?.init(el);
        }
      });
    }

    let observer = new MutationObserver(onMutate);
    observer.observe(el, {
      attributeFilter: ["aria-hidden"],
    });
  }

  speak(text, priority) {
    var el = document.createElement("div");
    var id = "speak-" + Date.now();
    el.setAttribute("id", id);
    el.setAttribute("aria-live", priority || "polite");
    el.classList.add("sr-only");
    document.body.appendChild(el);

    setTimeout(function () {
      document.getElementById(id).innerHTML = text;
    }, 50);

    setTimeout(function () {
        document.body.removeChild(document.getElementById(id));
    }, 1000);
  }
}

export default Accessibility;
