"use strict";

import Utility from "./Utility";

class Global {
  static scrollBarWidth = Utility.getScrollBarWidth();
  static deactivationTimeouts = new Map();
  static activationTimeouts = new Map();
  static mouseOverDropdown = false;
  static focusableQueryString =
    'a[href], area[href], input, select, textarea, button, iframe, [tabindex="0"], [contentEditable=true], .focusable-element';
}

export default Global;
